import {
    IRouteConfig,
    IRoutingConfig,
} from "../../_shared/models/models-index";

export class RoutingConfig implements IRoutingConfig {
    public routes: IRouteConfig[] = [
        {
            id: "sales",
            category: "sales",
            default: true,
            defaultRoute: "/sales/overview",
            displayName: "Sales",
            displayInNav: true,
            iconClass: "fa fa-usd",
            roles: undefined,
            children: [
                {
                    parent: "/sales",
                    route: "overview",
                    displayName: "Overview",
                    pdfEnabled: false,
                    iconClass: "fa fa-users",
                    breadcrumbHelpEnabled: true,
                    roles: undefined,
                },
                {
                    parent: "/sales",
                    route: "summary",
                    displayName: "Summary",
                    iconClass: "fa fa-list",
                    breadcrumbHelpEnabled: true,
                    roles: undefined,
                },
                {
                    parent: "/sales",
                    route: "vehicle",
                    displayName: "Vehicle",
                    iconClass: "fa fa-car",
                    breadcrumbHelpEnabled: true,
                    roles: undefined,
                },
            ],
        },
        {
            id: "website",
            default: true,
            category: "website",
            defaultRoute: "/website/overview",
            displayName: "Website",
            displayInNav: true,
            iconClass: "fa fa-globe",
            roles: undefined,
            children: [
                {
                    parent: "/website",
                    route: "overview",
                    displayName: "Overview",
                    pdfEnabled: false,
                    breadcrumbHelpEnabled: true,
                    iconClass: "fa fa-users",
                    roles: undefined,
                },
                {
                    parent: "/website",
                    route: "scorecard",
                    displayName: "Scorecard",
                    iconClass: "fa fa-list",
                    roles: undefined,
                },
                {
                    parent: "/website",
                    route: "summary",
                    displayName: "Summary",
                    iconClass: "fa fa-list",
                    breadcrumbHelpEnabled: true,
                    roles: undefined,
                },
                {
                    parent: "/website",
                    route: "referrer",
                    displayName: "Referrer",
                    iconClass: "fa fa-share",
                    roles: undefined,
                },
                {
                    parent: "/website",
                    route: "provider",
                    displayName: "Provider",
                    iconClass: "fa fa-cogs",
                    roles: ["System Administrator", "System Analyst", "Corporate"],
                },
            ],
        },
        {
            id: "digAd",
            category: "digAd",
            default: true,
            defaultRoute: "/digAd/overview",
            displayName: "Digital Advertising",
            displayInNav: true,
            iconClass: "fa fa-desktop",
            roles: undefined,
            children: [
                {
                    parent: "/digAd",
                    route: "overview",
                    displayName: "Overview",
                    pdfEnabled: false,
                    breadcrumbHelpEnabled: true,
                    iconClass: "fa fa-users",
                    roles: undefined,
                },
                {
                    parent: "/digAd",
                    route: "scorecard",
                    displayName: "Scorecard",
                    breadcrumbHelpEnabled: true,
                    iconClass: "fa fa-list",
                    roles: undefined,
                },
                {
                    parent: "/digAd",
                    route: "summary",
                    displayName: "Summary",
                    breadcrumbHelpEnabled: true,
                    iconClass: "fa fa-list",
                    roles: undefined,
                },
                {
                    parent: "/digAd",
                    route: "provider",
                    displayName: "Provider",
                    breadcrumbHelpEnabled: true,
                    iconClass: "fa fa-cogs",
                    roles: undefined,
                },
                {
                    parent: "/digAd",
                    route: "channel",
                    displayName: "Channel",
                    breadcrumbHelpEnabled: true,
                    iconClass: "fa fa-cogs",
                    roles: undefined,
                },
            ],
        },
        {
            id: "provider-tools",
            category: "provider-tools",
            default: true,
            defaultRoute: "/provider-tools/service-scheduler-overview",
            displayName: "Provider Tools",
            displayInNav: true,
            iconClass: "fa fa-wrench",
            roles: undefined,
            children: [
                {
                    parent: "/provider-tools",
                    route: "service-scheduler-overview",
                    displayName: "Service Scheduler",
                    pdfEnabled: true,
                    breadcrumbHelpEnabled: true,
                    iconClass: "fa fa-exchange",
                    roles: undefined,
                },
            ],
        },
        {
            id: "management",
            category: "management",
            defaultRoute: "/management/users",
            displayName: "Management",
            displayInNav: true,
            iconClass: "fa fa-cog",
            roles: ["System Administrator", "System Analyst"],
            children: [
                {
                    parent: "/management",
                    route: "content",
                    displayName: "Content",
                    iconClass: "fa fa-code",
                    roles: ["System Administrator", "System Analyst"],
                },
                {
                    parent: "/management",
                    route: "report-views-configuration",
                    displayName: "Report Views",
                    iconClass: "fa fa-code",
                    roles: ["System Administrator", "System Analyst"],
                    children: [
                        {
                            id: "report-views-configuration/create",
                            parent: "report-views-configuration",
                            route: "create",
                            displayName: "Create Report View",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                        {
                            id: "report-views-configuration/update",
                            parent: "report-views-configuration",
                            route: "update",
                            displayName: "Edit Report View",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                    ],
                },
                {
                    parent: "/management",
                    route: "data-sets-configuration",
                    displayName: "Data Sets",
                    iconClass: "fa fa-code",
                    roles: ["System Administrator", "System Analyst"],
                    children: [
                        {
                            id: "data-sets-configuration/create",
                            parent: "data-sets-configuration",
                            route: "create",
                            displayName: "Create Data Set",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                        {
                            id: "data-sets-configuration/update",
                            parent: "data-sets-configuration",
                            route: "update",
                            displayName: "Edit Data Set",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                    ],
                },
                {
                    parent: "/management",
                    route: "query-configuration",
                    displayName: "Queries",
                    iconClass: "fa fa-code",
                    roles: ["System Administrator", "System Analyst"],
                    children: [
                        {
                            id: "query-configuration/create",
                            parent: "query-configuration",
                            route: "create",
                            displayName: "Create Query",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                        {
                            id: "query-configuration/update",
                            parent: "query-configuration",
                            route: "update",
                            displayName: "Edit Query",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                    ],
                },
                {
                    parent: "/management",
                    route: "filter-configuration",
                    displayName: "Filters",
                    iconClass: "fa fa-code",
                    roles: ["System Administrator", "System Analyst"],
                    children: [
                        {
                            id: "filter-configuration/create",
                            parent: "filter-configuration",
                            route: "create",
                            displayName: "Create Filter",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                        {
                            id: "filter-configuration/update",
                            parent: "filter-configuration",
                            route: "update",
                            displayName: "Edit Filter",
                            iconClass: "fa fa-code",
                            roles: ["System Administrator"],
                        },
                    ],
                },
                {
                    parent: "/management",
                    route: "users",
                    displayName: "Users",
                    iconClass: "fa fa-users",
                    roles: ["System Administrator", "System Analyst"],
                },
            ],
        },
    ];
}
